<template>
    <div>
        <div v-for="(number,id) in row.item.offerings"
             :key="id"
        >
            <a href="#"
               @click.prevent="$root.$children[0].openModal('kva-modal', {id:id}, refreshTable)">
                {{ number }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>